import React from "react"
import styled from "styled-components"
import implantBefore1 from "../../../images/openday/implantologia/SorrisosANTES.png"
import implantAfter1 from "../../../images/openday/implantologia/SorrisosDEPOIS.png"
import implantBefore2 from "../../../images/openday/implantologia/Sorrisos2ANTES.png"
import implantAfter2 from "../../../images/openday/implantologia/Sorrisos2DEPOIS.png"
import arrowOpen from "../../../images/openday/implantologia/arrow-open.svg"
import font from "../../../fonts/fonts.module.scss"
import ReactCompareImage from "react-compare-image"
import Fade from "react-reveal"

const Container = styled.div`
  position: relative;
  background: #fff;
  padding-top: 25vh;

  .implant-img-first {
    margin: 100px 0;
    grid-column: 8 / -1;
    width: 100%;

    img {
      border-radius: 40px;
    }

    .implant-img {
      object-fit: cover;
    }
  }
  .implant-img-second {
    margin: 100px 0;
    grid-column: 1 / 6;
    width: 100%;
    border-radius: 40px;

    img {
      border-radius: 40px;
    }

    .implant-img {
      object-fit: cover;
    }
  }

  .right-side {
    display: flex;
    padding-top: 10vh;
    padding-bottom: 20vh; //QUANDO TIVER O VIDEO VOLTA PARA 80VH

    .grid-container {
      margin: 0;
    }

    .right-txt {
      grid-column: 7 / 12;
      margin: 0 auto;
      font-size: clamp(2.5rem, 0.4167rem + 2.7778vw, 3.75rem);
      text-align: center;
      display: flex;
      align-items: center;
    }

    .button-openday {
      background: #e6d1c0;
      grid-column: 1 / 7;
      padding: 40px 70px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 0 40px 40px 0;

      h2 {
        font-size: clamp(2.5rem, 0.4167rem + 2.7778vw, 3.75rem);
        text-transform: uppercase;
      }

      p {
        font-size: clamp(1.25rem, 0.2083rem + 1.3889vw, 1.875rem);
      }
    }
  }
`

// Caixa do lado esquerdo com dimensões fixas
const RightTextBox = styled.div`
  grid-column: 1 / 6;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h2 {
    font-size: clamp(2.5rem, 0.4167rem + 2.7778vw, 3.75rem);
    text-transform: uppercase;
    font-weight: bold;
  }

  b {
    margin-top: 39px;
    margin-bottom: 10px;
    font-size: clamp(1.25rem, 0.8333rem + 0.5556vw, 1.5rem);
  }

  p {
    font-size: clamp(1.25rem, 0.8333rem + 0.5556vw, 1.5rem);
  }
`
const LeftTextBox = styled.div`
  grid-column: 8 / -1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  b {
    margin-top: 39px;
    margin-bottom: 10px;
    font-size: clamp(1.25rem, 0.8333rem + 0.5556vw, 1.5rem);
  }

  p {
    font-size: clamp(1.25rem, 0.8333rem + 0.5556vw, 1.5rem);
  }
`

const BoxSlides = () => {
  return (
    <Container>
      <div className="grid-container">
        <RightTextBox>
          <Fade>
            <h2 className={font.rB}>VANTAGENS DOS IMPLANTES DENTÁRIOS</h2>
            <b className={font.rB}>• Recupere a estética do seu sorriso</b>
            <p className={font.rR}>
              A prótese fixa dentária é praticamente igual aos dentes naturais,
              proporcionando um sorriso harmonioso e natural.
            </p>
            <b className={font.rB}>• Mastigue com facilidade e conforto</b>
            <p className={font.rR}>
              Mais confortável que prótese removível, a dentição fixa
              permitir-lhe-á recuperar a capacidade de mastigar corretamente,
              melhorando a digestão e nutrição.
            </p>
          </Fade>
        </RightTextBox>
        <div className="implant-img-first">
          <Fade>
            <ReactCompareImage
              hover
              leftImage={implantBefore1}
              leftImageAlt="Implantologia Avançada"
              rightImage={implantAfter1}
              rightImageAlt="Implantologia Avançada"
              sliderPositionPercentage={0.2}
            />
          </Fade>
        </div>

        <div className="implant-img-second">
          <Fade>
            <ReactCompareImage
              hover
              leftImage={implantBefore2}
              leftImageAlt="Implantologia Avançada"
              rightImage={implantAfter2}
              rightImageAlt="Implantologia Avançada"
              sliderPositionPercentage={0.2}
            />
          </Fade>
        </div>
        <LeftTextBox className="container-layout">
          <Fade>
            <b className={font.rB}>• Restaure um sorriso firme e seguro</b>
            <p className={font.rR}>
              Os implantes são fixos no osso, oferecendo uma base sólida para os
              novos dentes, evitando que se movam.
            </p>
            <b className={font.rB}>• Sinta confiança e bem-estar</b>
            <p className={font.rR}>
              A técnica All-on-4 permite-lhe recuperar os dentes num só dia,
              melhorando a sua autoestima e qualidade de vida de forma imediata.
            </p>
            <b className={font.rB}>• Mantenha um sorriso saudável</b>
            <p className={font.rR}>
              A manutenção do seu novo implante dentário é fácil, basta manter
              os mesmos cuidados, tal como faria com os seus dentes naturais.
            </p>
          </Fade>
        </LeftTextBox>
      </div>

      <div className="right-side">
        <div className="grid-container">
          <Fade left>
            <div className="button-openday">
              <div>
                <h2 className={font.rB}>
                  inscreva-se <br /> hoje!
                </h2>
                <p className={font.rR}>E aproveite as vantagens</p>
              </div>
              <a href="#form-openday">
                <img src={arrowOpen} />
              </a>
            </div>
          </Fade>
          <Fade>
            <p className={font.rSB + " right-txt"}>
              Recupere a confiança e bem-estar com
              <br /> dentição fixa imediata!
            </p>
          </Fade>
        </div>
      </div>
    </Container>
  )
}

export default BoxSlides
