import React from 'react'

export const handleSubmit = (clinic) => {
    switch (clinic) {
        case 'porto@carreiradentalclinic.pt':
        case clinic.includes('Porto'):
        case clinic.includes('porto'):
            return 'Clínica Porto'
        case 'aveiro@carreiradentalclinic.pt':
        case clinic.includes('Aveiro'):
        case clinic.includes('aveiro'):
            return 'Clínica Aveiro'
        case 'agueda@carreiradentalclinic.pt':
        case clinic.includes('Águeda'):
        case clinic.includes('águeda'):
        case clinic.includes('agueda'):
            return 'Clínica Águeda'
        case 'viseu@carreiradentalclinic.pt':
        case clinic.includes('Viseu'):
        case clinic.includes('viseu'):
            return 'Clínica Viseu'
        case 'oaz@carreiradentalclinic.pt':
        case clinic.includes('Oliveira'):
        case clinic.includes('oliveira'):
            return 'Clínica Oliveira de Azeméis'
        default:
            return clinic
    }
}