import React from "react"
import styled from "styled-components"
import Couple from "../../../images/openday/implantologia/couple.png"
import Sr from "../../../images/openday/implantologia/sr.png"
import font from "../../../fonts/fonts.module.scss"
import Fade from "react-reveal"

const Container = styled.div`
  position: relative;
  background: #000;

  .implant-img-first {
    margin: -50px 0 50px 0;
    grid-column: 1 / -1;
    width: 100%;

    img {
      border-radius: 40px;
      width: 100%;
    }

    .implant-img {
      object-fit: cover;
    }
  }
  .implant-img-second {
    margin: 0 0 -50px 0;
    grid-column: 1 / -1;
    width: 100%;
    border-radius: 40px;

    img {
      border-radius: 40px;
      width: 100%;
      position: relative;
      z-index: 99;
    }

    .implant-img {
      object-fit: cover;
    }
  }
`

// Caixa do lado esquerdo com dimensões fixas
const RightTextBox = styled.div`
  grid-column: 1 / -1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;

  h2 {
    font-size: clamp(1.875rem, 1.6667rem + 1.1111vw, 2.5rem);
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 10px;
  }

  p {
    clamp(1rem,0.7917rem + 1.1111vw,1.625rem)margin-top: 39px;
    margin-bottom: 50px;
  }
`
const LeftTextBox = styled.div`
  grid-column: 1 / -1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;

  h2 {
    font-size: clamp(1.875rem, 1.6667rem + 1.1111vw, 2.5rem);
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 10px;
  }

  p {
    clamp(1rem,0.7917rem + 1.1111vw,1.625rem)margin-top: 39px;
    margin-bottom: 50px;
  }
`

const BoxOldPeopleMobile = () => {
  return (
    <Container>
      <div className="grid-container">
        <div className="implant-img-first">
          <Fade>
            <img
              src={Couple}
              title="Sorria com confiança"
              alt="Casal sorrindo juntos"
            />
          </Fade>
        </div>
        <RightTextBox>
          <Fade>
            <h2 className={font.rB}>SORRIA COM CONFIANÇA!</h2>
            <p className={font.rR}>
              Os implantes dentários são a solução ideal para a falta de dentes
              e para recuperar, de forma natural, a beleza do sorriso e a função
              mastigatória.
            </p>
          </Fade>
        </RightTextBox>

        <LeftTextBox>
          <Fade>
            <h2 className={font.rB}>DENTIÇÃO FIXA IMEDIATA</h2>
            <p className={font.rR}>
              Em apenas 1 dia transforme o seu sorriso para a vida inteira.
            </p>
          </Fade>
        </LeftTextBox>
        <div className="implant-img-second">
          <Fade>
            <img
              src={Sr}
              alt="Pessoa de mais idade na clínica dentária "
              title="Dentição Fixa Imediata"
            />
          </Fade>
        </div>
      </div>
    </Container>
  )
}

export default BoxOldPeopleMobile
