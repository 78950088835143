import React, { useState } from "react"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
import arrowDown from "../../../images/openday/implantologia/arrow-open.svg" // Sua imagem de seta
import styled from "styled-components"
import font from "../../../fonts/fonts.module.scss"

const ContainerFAQ = styled.div`
  h2 {
    font-size: clamp(2.5rem, 0.4167rem + 2.7778vw, 3.75rem);
    text-transform: uppercase;
    text-align: center;
    grid-column: 4 / 10;
    margin-bottom: 99px;
  }

  .faq-container {
    grid-column: 3 / 11;
    width: 100%;
    margin: 0 auto;
  }

  .accordion {
    border: none;
  }

  .accordion__item {
    background: transparent;
    border: none;

    &:hover {
      box-shadow: 0px 0px 15px #00000040;
    }
  }

  .accordion__button {
    background: transparent;
    padding: 50px;
    cursor: pointer;
    font-size: 1.2rem;
    border: none;
    outline: none;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &::before {
      display: none;
    }
  }

  .accordion-arrow {
    width: 20px;
    height: 20px;
    transition: transform 0.3s ease;
  }

  .rotate {
    transform: rotate(180deg); /* Gira a seta para cima */
  }

  .accordion__panel {
    padding: 50px;
    background-color: #fff;
    border: none;
  }
`

const FAQAccordion = () => {
  return (
    <ContainerFAQ>
      <div className="grid-container space">
        <h2 className={font.rB}>Perguntas Frequentes</h2>
        <div className={font.rSB + " faq-container"}>
          <Accordion allowZeroExpanded={true}>
            {" "}
            {/* AllowZeroExpanded permite fechar todos */}
            <AccordionItem uuid={0}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  O que posso esperar do Open Day de Implantologia Avançada?
                  <img
                    src={arrowDown}
                    alt="Seta"
                    className={`accordion-arrow`}
                  />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p className={font.rR}>
                  Poderá usufruir de condições especiais e descobrir como a
                  técnica All-on-4 pode recuperar o seu sorriso de forma rápida
                  e eficaz. Terá acesso a uma avaliação completa com raio-x
                  panorâmico, scan intraoral e registo fotográfico, para que
                  possa conhecer as vantagens da reabilitação total com
                  implantes e dar o primeiro passo para recuperar o seu
                  bem-estar.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem uuid={1}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  O que é um Implante Dentário?
                  <img
                    src={arrowDown}
                    alt="Seta"
                    className={`accordion-arrow`}
                  />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p className={font.rR}>
                  Um implante dentário é, essencialmente, uma raiz artificial,
                  em titânio puro, que é cirurgicamente inserida no osso para
                  substituir a raiz de um dente natural. Após a sua integração
                  no osso, o implante serve como base para a fixação de uma
                  coroa dentária, ponte ou prótese, restaurando a função
                  mastigatória e estética do dente perdido. Os implantes
                  dentários oferecem uma solução duradoura e estável para a
                  substituição de dentes, assemelhando-se em aparência e função
                  aos dentes naturais.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem uuid={2}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  A técnica All-on-4 é indicada para mim?
                  <img
                    src={arrowDown}
                    alt="Seta"
                    className={`accordion-arrow`}
                  />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p className={font.rR}>
                  O All-on-4, técnica de Implantologia Avançada, pode ser
                  indicado para quem sofre de perda total de dentes ou para quem
                  ainda os possui, mas em mau estado, exigindo substituição.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem uuid={3}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  Como devo cuidar dos meus Implantes Dentários?
                  <img
                    src={arrowDown}
                    alt="Seta"
                    className={`accordion-arrow`}
                  />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p className={font.rR}>
                  Cuidar dos seus implantes dentários é muito semelhante aos
                  cuidados que teria com os seus dentes naturais. A higiene oral
                  diária com escova, pasta de dentes e fio dentário, uma
                  alimentação cuidada e visitas regulares ao dentista são a
                  chave para manter os seus implantes saudáveis e com um aspeto
                  natural durante muitos anos.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem uuid={4}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  Posso substituir uma dentadura convencional por Implantes?
                  <img
                    src={arrowDown}
                    alt="Seta"
                    className={`accordion-arrow`}
                  />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p className={font.rR}>
                  Sim, pode substituir a sua dentadura convencional por uma
                  prótese fixa dentária, desfrutando de maior conforto,
                  estabilidade e uma função mastigatória e fonética mais
                  natural. Ao contrário das dentaduras removíveis, os implantes
                  dentários impedem a reabsorção óssea, preservando a estrutura
                  do maxilar.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem uuid={5}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  Com que idade se pode fazer um Implante Dentário?
                  <img
                    src={arrowDown}
                    alt="Seta"
                    className={`accordion-arrow`}
                  />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p className={font.rR}>
                  Embora não exista uma idade específica para colocar implantes
                  dentários, geralmente é recomendado aguardar a total erupção
                  dos dentes definitivos. Esta fase varia de pessoa para pessoa
                  e pode ser influenciada por fatores como o género e o
                  desenvolvimento individual. No entanto, uma avaliação clínica
                  detalhada é fundamental para determinar o momento ideal para a
                  colocação dos implantes em cada paciente.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem uuid={6}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  Quanto tempo dura um Implante Dentário?
                  <img
                    src={arrowDown}
                    alt="Seta"
                    className={`accordion-arrow`}
                  />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p className={font.rR}>
                  Com os devidos cuidados, os implantes dentários podem durar
                  muitos anos, até mesmo uma vida inteira! A sua durabilidade
                  depende de fatores como a qualidade do implante, a higiene
                  oral do paciente, os seus hábitos e a sua saúde geral. Visitas
                  regulares ao dentista e uma boa rotina de cuidados em casa são
                  fundamentais para garantir que os seus implantes se mantenham
                  saudáveis e funcionais a longo prazo.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </div>
      </div>
    </ContainerFAQ>
  )
}

export default FAQAccordion
