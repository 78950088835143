import React from "react"
import styled from "styled-components"
import implantBefore1 from "../../../images/openday/implantologia/SorrisosANTES.png"
import implantAfter1 from "../../../images/openday/implantologia/SorrisosDEPOIS.png"
import implantBefore2 from "../../../images/openday/implantologia/Sorrisos2ANTES.png"
import implantAfter2 from "../../../images/openday/implantologia/Sorrisos2DEPOIS.png"
import arrowOpen from "../../../images/openday/implantologia/arrow-open.svg"
import font from "../../../fonts/fonts.module.scss"
import ReactCompareImage from "react-compare-image"
import { Fade } from "react-reveal"

const Container = styled.div`
  position: relative;
  background: #fff;
  grid-column: 1 / -1;

  .implant-img-first {
    margin: 100px 0;
    width: 100%;

    img {
      border-radius: 40px;
    }

    .implant-img {
      object-fit: cover;
    }
  }
  .implant-img-second {
    margin: 100px 0;
    width: 100%;
    border-radius: 40px;

    img {
      border-radius: 40px;
    }

    .implant-img {
      object-fit: cover;
    }
  }
`

// Caixa do lado esquerdo com dimensões fixas
const ButtonOpen = styled.div`
  .right-side {
    padding-bottom: 10vh; //QUANDO TIVER O VIDEO VOLTA PARA 60VH

    .right-txt {
      margin-top: 50px;
      font-size: clamp(1.875rem, 1.6667rem + 1.1111vw, 2.5rem);
      text-align: center;
    }

    .button-openday {
      background: #e6d1c0;
      padding: 40px 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 0 40px 40px 0;

      .arrow {
        width: 50%;
      }

      a {
        display: flex;
        justify-content: center;
      }

      h2 {
        font-size: clamp(1.875rem, 1.6667rem + 1.1111vw, 2.5rem);
        text-transform: uppercase;
      }

      p {
        font-size: clamp(1rem, 0.7917rem + 1.1111vw, 1.625rem);
      }
    }
  }
`
const RightTextBox = styled.div`
  text-align: center;

  h2 {
    font-size: clamp(1.875rem, 1.6667rem + 1.1111vw, 2.5rem);
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 39px;
  }
  b {
    font-size: clamp(1rem, 0.7917rem + 1.1111vw, 1.625rem);
  }

  p {
    font-size: clamp(1rem, 0.7917rem + 1.1111vw, 1.625rem);
    margin: 10px 0;
  }
`
const LeftTextBox = styled.div`
  text-align: center;

  b {
    margin-top: 39px;
    font-size: clamp(1rem, 0.7917rem + 1.1111vw, 1.625rem);
  }

  p {
    font-size: clamp(1rem, 0.7917rem + 1.1111vw, 1.625rem);
    margin: 10px 0;
  }
`

const BoxSlidesMobile = () => {
  return (
    <>
      <div className="grid-container">
        <Container>
          <div className="implant-img-first">
            <ReactCompareImage
              hover
              leftImage={implantBefore1}
              leftImageAlt="implantologia avançada"
              rightImage={implantAfter1}
              rightImageAlt="implantologia avançada"
              sliderPositionPercentage={0.2}
            />
          </div>
          <RightTextBox>
            <Fade>
              <h2 className={font.rB}>VANTAGENS DOS IMPLANTES DENTÁRIOS</h2>
              <b className={font.rB}>• Recupere a estética do seu sorriso</b>
              <p className={font.rR}>
                A prótese fixa dentária é praticamente igual aos dentes
                naturais, proporcionando um sorriso harmonioso e natural.
              </p>
              <b className={font.rB}>• Mastigue com facilidade e conforto</b>
              <p className={font.rR}>
                Mais confortável que prótese removível, a dentição fixa
                permitir-lhe-á recuperar a capacidade de mastigar corretamente,
                melhorando a digestão e nutrição.
              </p>
            </Fade>
          </RightTextBox>

          <div className="implant-img-second">
            <ReactCompareImage
              hover
              leftImage={implantBefore2}
              leftImageAlt="implantologia avançada"
              rightImage={implantAfter2}
              rightImageAlt="implantologia avançada"
              sliderPositionPercentage={0.2}
            />
          </div>

          <LeftTextBox className="container-layout">
            <Fade>
              <b className={font.rB}>• Restaure um sorriso firme e seguro</b>
              <p className={font.rR}>
                Os implantes são fixos no osso, oferecendo uma base sólida para
                os novos dentes, evitando que se movam.
              </p>
              <b className={font.rB}>• Sinta confiança e bem-estar</b>
              <p className={font.rR}>
                A técnica All-on-4 permite-lhe recuperar os dentes num só dia,
                melhorando a sua autoestima e qualidade de vida de forma
                imediata.
              </p>
              <b className={font.rB}>• Mantenha um sorriso saudável</b>
              <p className={font.rR}>
                A manutenção do seu novo implante dentário é fácil, basta manter
                os mesmos cuidados, tal como faria com os seus dentes naturais.
              </p>
            </Fade>
          </LeftTextBox>
        </Container>
      </div>
      <ButtonOpen>
        <div className="right-side">
          <div className="button-openday">
            <div>
              <h2 className={font.rB}>
                inscreva-se <br /> hoje!
              </h2>
              <p className={font.rR}>E aproveite as vantagens</p>
            </div>
            <a href="#form-openday">
              <img src={arrowOpen} className="arrow" />
            </a>
          </div>
          <p className={font.rSB + " right-txt"}>
            Recupere a confiança e bem-estar com dentição fixa imediata!
          </p>
        </div>
      </ButtonOpen>
    </>
  )
}

export default BoxSlidesMobile
