import React from "react"
import styled from "styled-components"
import arrowOpen from "../../../images/openday/implantologia/arrow-open.svg"
import font from "../../../fonts/fonts.module.scss"

const Container = styled.div`
  position: relative;
  background: #000;
  color: #fff;
  padding: 21.76278563656148vh 0;

  .title {
    grid-column: 1 / -1;

    .top {
      font-size: clamp(0.875rem, 0.7917rem + 0.4444vw, 1.125rem);
      text-align: left;
    }

    .middle {
      font-size: clamp(2.3125rem, 1.5208rem + 4.2222vw, 4.6875rem);
      text-align: center;
      text-transform: uppercase;
    }

    .bottom {
      font-size: clamp(0.875rem, 0.7917rem + 0.4444vw, 1.125rem);
      text-align: right;
    }
  }

  .container-info {
    grid-column: 1 / -1;
    width: 100%;
    margin: 100px 0;
    text-align: center;
  }
  .date-openday {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .button-openday {
    background: #e6d1c0;
    padding: 40px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: right;
    border-radius: 40px 0 0 40px;
    color: #000;
    margin-top: 50px;

    .arrow {
      width: 50%;
    }

    a {
      display: flex;
      justify-content: center;
    }

    h2 {
      font-size: clamp(1.875rem, 1.6667rem + 1.1111vw, 2.5rem);
      text-transform: uppercase;
    }

    p {
      font-size: clamp(1rem, 0.7917rem + 1.1111vw, 1.625rem);
    }
  }

  .date {
    font-size: clamp(1rem, 0.0625rem + 1.25vw, 1.5625rem);
    margin-bottom: 7px;
  }
`

// Caixa do lado esquerdo com dimensões fixas
const TextBox = styled.div`
  margin-left: 5.98vw;

  h2 {
    font-size: clamp(1.875rem, 1.6667rem + 1.1111vw, 2.5rem);
    text-transform: uppercase;
  }

  .subtitle {
    font-size: clamp(1rem, 0.7917rem + 1.1111vw, 1.625rem);
    padding-bottom: 50px;
  }

  .text {
    font-size: clamp(1rem, 0.7917rem + 1.1111vw, 1.625rem);
  }

  li {
    font-size: clamp(1.25rem, 0.8333rem + 0.5556vw, 1.5rem);
    list-style-type: none;
  }
`

const BoxInfoMobile = () => {
  return (
    <Container>
      <div className="grid-container">
        <div className="title">
          <p className={font.rR + " top"}>Novembro mês da</p>
          <p className={font.rB + " middle"}>implantologia</p>
          <p className={font.rR + " bottom"}> avançada</p>
        </div>
      </div>
      <div className="grid-container">
        <div className="container-info">
          <TextBox>
            <h2 className={font.rB}>
              na carreira
              <br /> dental clinic
            </h2>
            <p className={font.rR + " subtitle"}>
              Recupere o seu bem-estar e o seu sorriso com a reabilitação total
              com implantes
            </p>
            <p className={font.rR + " text"}>
              Em Novembro, a CARREIRA DENTAL CLINIC oferece condições especiais
              para si.
              <br /> Descubra como a dentição fixa com implantes, através da
              técnica de Implantologia Avançada All-on-4, pode ser a solução
              ideal para recuperar o seu sorriso de forma cómoda, rápida e
              eficaz.
              <br />
              <br />
              Aproveite esta oportunidade e agende já a sua avaliação completa,
              que inclui:
              <br />
              <br />
            </p>
            <ul className={font.rR}>
              <li> • Raio - X Panorâmico;</li> <li> • Scan Intraoral;</li>
              <li> • Registo Fotográfico.</li>
            </ul>
          </TextBox>
        </div>
      </div>
      <div className="date-openday">
        <p className={font.rR + " date"}>
          <b>26 Nov •</b> Oliveira de Azeméis | <b>28 e 29 Nov •</b> Porto
        </p>
        <p className={font.rR + " date"}>
          <b>03 Dez •</b> Viseu | <b>05 Dez •</b> Águeda
        </p>
        <p className={font.rR + " date"}>
          <b>06 Dez •</b>
          Aveiro
        </p>
      </div>
      <div className="button-openday">
        <a href="#form-openday">
          <img src={arrowOpen} className="arrow" />
        </a>
        <div>
          <h2 className={font.rB}>
            garanta a<br /> sua vaga!
          </h2>
          <p className={font.rR}>E inscreva-se já!</p>
        </div>
      </div>
    </Container>
  )
}

export default BoxInfoMobile
