import React from "react"
import styled from "styled-components"
import arrowOpen from "../../../images/openday/implantologia/arrow-open.svg"
import font from "../../../fonts/fonts.module.scss"
import { Fade } from "react-reveal"

const Container = styled.div`
  position: relative;
  background: #000;
  color: #fff;
  padding: 21.76278563656148vh 0;

  .title {
    grid-column: 4 / 9;
    width: 34vw;

    @media only screen and (max-width: 1440px) {
      grid-column: 4 / 10;
      width: 45vw;
    }

    .top {
      font-size: clamp(2.1875rem, -0.1042rem + 3.0556vw, 3.5625rem);
      text-align: left;
    }

    .middle {
      font-size: clamp(2.3125rem, 1.5208rem + 4.2222vw, 5rem);
      text-align: center;
      text-transform: uppercase;

      @media only screen and (min-width: 1921px) {
        font-size: clamp(3.75rem, -5.4808rem + 7.6923vw, 6.25rem);
      }
    }

    .bottom {
      font-size: clamp(3.25rem, 0.2292rem + 4.0278vw, 5.0625rem);
      text-align: right;
    }
  }

  .container-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 100px 0;

    .date-openday {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .button-openday {
        background: #e6d1c0;
        padding: 40px 130px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 50vw;
        text-align: right;
        border-radius: 40px 0 0 40px;
        color: #000;
        margin-top: 50px;

        h2 {
          font-size: clamp(2.5rem, 0.4167rem + 2.7778vw, 3.75rem);
          text-transform: uppercase;
        }

        p {
          font-size: clamp(1.25rem, 0.2083rem + 1.3889vw, 1.875rem);
        }
      }

      .date {
        font-size: clamp(1rem, 0.0625rem + 1.25vw, 1.5625rem);
        margin-bottom: 7px;
      }
    }
  }
`

// Caixa do lado esquerdo com dimensões fixas
const TextBox = styled.div`
  margin-left: 5.98vw;
  width: 35vw;

  h2 {
    font-size: clamp(2.5rem, 0.4167rem + 2.7778vw, 3.75rem);
    text-transform: uppercase;
  }

  .subtitle {
    font-size: clamp(1.25rem, 0.2083rem + 1.3889vw, 1.875rem);
    padding-bottom: 90px;
  }

  .text {
    font-size: clamp(1.25rem, 0.8333rem + 0.5556vw, 1.5rem);
  }

  li {
    font-size: clamp(1.25rem, 0.8333rem + 0.5556vw, 1.5rem);
  }
`

const BoxInfo = () => {
  return (
    <Container>
      <div className="grid-container">
        <div className="title">
          <p className={font.rR + " top"}>Novembro mês da</p>
          <p className={font.rB + " middle"}>implantologia</p>
          <p className={font.rR + " bottom"}> avançada</p>
        </div>
      </div>
      <div className="container-info">
        <TextBox>
          <h2 className={font.rB}>
            na carreira
            <br /> dental clinic
          </h2>
          <p className={font.rR + " subtitle"}>
            Recupere o seu bem-estar e o seu sorriso com a reabilitação total
            com implantes
          </p>
          <p className={font.rR + " text"}>
            Em Novembro, a CARREIRA DENTAL CLINIC oferece condições especiais
            para si.
            <br /> Descubra como a dentição fixa com implantes, através da
            técnica de Implantologia Avançada All-on-4, pode ser a solução ideal
            para recuperar o seu sorriso de forma cómoda, rápida e eficaz.
            <br />
            <br />
            Aproveite esta oportunidade e agende já a sua avaliação completa,
            que inclui:
            <br />
            <br />
          </p>
          <ul className={font.rR}>
            <li> Raio - X Panorâmico;</li> <li> Scan Intraoral;</li>
            <li> Registo Fotográfico.</li>
          </ul>
        </TextBox>
        <div className="date-openday">
          <p className={font.rR + " date"}>
            <b>26 Nov •</b> Oliveira de Azeméis | <b>28 e 29 Nov •</b> Porto
          </p>
          <p className={font.rR + " date"}>
            <b>03 Dez •</b> Viseu | <b>05 Dez •</b> Águeda | <b>06 Dez • </b>
            Aveiro
          </p>
          <Fade right>
            <div className="button-openday">
              <a href="#form-openday">
                <img src={arrowOpen} />
              </a>
              <div>
                <h2 className={font.rB}>
                  garanta a<br /> sua vaga!
                </h2>
                <p className={font.rR}>E inscreva-se já!</p>
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </Container>
  )
}

export default BoxInfo
